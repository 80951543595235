import './App.css';
import LandScapeProductsScreen from './Screens/LandScapeScreens/ProductsScreen'
import LandScapeSignUpScreen from './Screens/LandScapeScreens/SignUpScreen'
import LandScapeWelcomeScreen from './Screens/LandScapeScreens/WelcomeScreen'
import LandScapeHIMScreen from './Screens/LandScapeScreens/HIMScreen'
import LandScapeDocScreen from './Screens/LandScapeScreens/DocScreen'
import LandscapeDashBoard from './Screens/LandScapeScreens/LandscapeDashBoard';
import LandScapeLoginScreen from './Screens/LandScapeScreens/LoginScreen'

import PortraitWelcomeScreen from './Screens/PortraitScreens/WelcomeScreen'
import PortraitLoginScreen from './Screens/PortraitScreens/LoginScreen'
import PortraitSignUpScreen from './Screens/PortraitScreens/SignUpScreen'
import PortraitProductsScreen from './Screens/PortraitScreens/ProductsScreen'
import PortraitHIMScreen from './Screens/PortraitScreens/HIMScreen'
import PortraitDocScreen from './Screens/PortraitScreens/DocScreen'
import PostDetail from './Screens/LandScapeScreens/PostDetail';
import NewPost from './Screens/LandScapeScreens/CreatePost';
import { Routes, Route } from 'react-router-dom'
function App() {
  const orientation = window.innerWidth > window.innerHeight ? true : false;

  return (
    <div className='App'>
        <Routes>
            <Route element={(orientation === true) ? <LandScapeWelcomeScreen/> : <PortraitWelcomeScreen/>} path={'/'}></Route>
            <Route element={(orientation === true) ? <LandScapeSignUpScreen/> : <PortraitSignUpScreen/>} path='/SignUp'></Route>
            <Route element={(orientation === true) ? <LandScapeProductsScreen/> : <PortraitProductsScreen/>} path='/Product'></Route>
            <Route element={(orientation === true) ? <LandScapeHIMScreen/> : <PortraitHIMScreen/>} path='/Him'></Route>
            <Route element={(orientation === true) ? <LandScapeDocScreen/> : <PortraitDocScreen/>} path='/Doc'></Route>
            <Route element={(orientation === true) ? <LandscapeDashBoard/> : <LandscapeDashBoard/>} path='/Chat'></Route>
            <Route element={(orientation === true) ? <PostDetail/>: <PostDetail/>} path="/post/:QuestionId/:UserId" ></Route>
            <Route element={(orientation === true) ? <NewPost/>: <NewPost/>} path="/NewPost" ></Route>
            <Route element={(orientation === true) ? <LandScapeLoginScreen/> : <PortraitLoginScreen/>} path='/Login'> </Route>
        </Routes>
    </div>
  );
}

export default App;
