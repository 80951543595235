/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  Flex,
  Image,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
export default function BLogin(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="744px"
      height="1400px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "BLogin")}
      {...rest}
    >
      <Flex
        gap="35px"
        direction="column"
        width="680px"
        height="583px"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="calc(50% - 291.5px - 180.5px)"
        left="4.3%"
        right="4.3%"
        borderRadius="35px"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Contents")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Login"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="30px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="36px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Enter username and password"
          {...getOverrideProps(overrides, "Subtitle1614301")}
        ></Text>
        <View
          width="unset"
          height="128px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Inputs")}
        >
          <TextField
            width="660px"
            height="54px"
            placeholder="Username"
            position="absolute"
            borderRadius="35px"
            top="calc(50% - 27px - 38px)"
            left="0%"
            right="0%"
            size="Default"
            {...getOverrideProps(overrides, "Username")}
          ></TextField>
          <TextField
            width="660px"
            height="54px"
            placeholder="Password"
            position="absolute"
            borderRadius="35px"
            top="calc(50% - 27px - -37px)"
            left="0px"
            size="Default"
            {...getOverrideProps(overrides, "Password")}
          ></TextField>
        </View>
        <Button
          width="317px"
          height="75px"
          shrink="0"
          buttontype="Default"
          isDisabled={false}
          children="Login"
          {...getOverrideProps(overrides, "Login")}
        ></Button>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Forgot your password? Request a new one!"
          {...getOverrideProps(overrides, "Subtitle1614306")}
        ></Text>
        <Button
          width="317px"
          height="75px"
          shrink="0"
          buttontype="Default"
          isDisabled={false}
          children="Reset Password"
          {...getOverrideProps(overrides, "ResetPassword")}
        ></Button>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="30px"
        right="36px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
      <Image
        width="85px"
        height="86px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="39px"
        left="32px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
