import { AWelcome } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'

const WelcomeScreen = () => {

    const navigate = useNavigate()
    
    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }        
  
    return (
        <AWelcome className='AWelcome'
        overrides={{
          "Logo": { src: logoImage},
          "Word": { src: wordImage},
          "Help" : { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk')},
          "Login" : { onClick: () => navigate('/Login')},
          "SignUp": {onClick: () => navigate('/SignUp')}
        }}/>
    ) 
  }

export default WelcomeScreen;