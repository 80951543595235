import React from "react";
import { Link } from "react-router-dom";

const Posts = (props) => {
  const { posts } = props;
  const convertTimeFormat = (d) => {
    let year = d.getFullYear();
    let month = ("0" + (d.getMonth() + 1)).slice(-2); 
    let day = ("0" + d.getDate()).slice(-2);
    let hour = ("0" + d.getHours()).slice(-2);
    let minute = ("0" + d.getMinutes()).slice(-2);
    let second = ("0" + d.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }
  return (
    <div className="list-group">
      {posts.map((post) => (
        <div className="chat-singlePost" key={post.QuestionId} >
          <Link to={`/post/${post.QuestionId}/${post.UserId}`}>
            <div className="d-flex w-100 justify-content-between" key={post.QuestionId} >
              <h5 style={{fontSize:20}}>{convertTimeFormat(new Date(post.Timestamp))}</h5>
              <small style={{fontSize:20}}>Created by {post.UserId}</small>
              <br/>
              <div className="chat-description">
                <small style={{fontSize:36}}>{post.Content.filter(content => content.Type === 0).map(content => content.Content)}</small>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Posts;
