/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function BWelcome(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="744px"
      height="1400px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "BWelcome")}
      {...rest}
    >
      <Flex
        gap="35px"
        direction="column"
        width="680px"
        height="1275px"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 637.5px - -62.5px)"
        left="4.3%"
        right="4.3%"
        borderRadius="35px"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Contents")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Welcome to KIOSK &#xA;Integration Exchange"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Powered by Kiosk"
          {...getOverrideProps(overrides, "Subtitle")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="18px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="21.600000381469727px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Greetings and a warm virtual handshake from the Kiosk team! We're absolutely thrilled to have you here. Whether you're an old friend or a new face, we're excited to introduce you to our world of innovative software products. At Kiosk, we're passionate about pushing the boundaries of technology to create products that simplify your life and elevate your experience. Our in-house web app is your gateway to explore our cutting-edge software solutions, each designed with your needs in mind. To get the most out of your visit, we invite you to sign up for an account. By becoming a part of our community, So, what are you waiting for? The future of software awaits, and it starts right here at Kiosk. Sign up today to embark on a journey of innovation, convenience, and endless possibilities."
          {...getOverrideProps(overrides, "Body")}
        ></Text>
        <Button
          width="317px"
          height="75px"
          shrink="0"
          buttontype="Default"
          isDisabled={false}
          children="Sign Up"
          {...getOverrideProps(overrides, "SignUp")}
        ></Button>
        <Button
          width="317px"
          height="75px"
          shrink="0"
          buttontype="Default"
          isDisabled={false}
          children="Login"
          {...getOverrideProps(overrides, "Login")}
        ></Button>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="30px"
        right="36px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
      <Image
        width="85px"
        height="86px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="39px"
        left="32px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/M6C6Wyj.png"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
