/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function BHIM(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="744px"
      height="1400px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "BHIM")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="column"
        width="680px"
        height="126px"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="calc(50% - 63px - 405px)"
        left="4.3%"
        right="4.3%"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="KNECT HIM"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Download new HIM version below"
          {...getOverrideProps(overrides, "Subtitle")}
        ></Text>
        <Flex
          gap="51px"
          direction="column"
          width="659px"
          height="301px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="50px 85px 50px 85px"
          {...getOverrideProps(overrides, "Machines")}
        >
          <View
            width="489px"
            height="75px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Machine1")}
          >
            <Button
              height="75px"
              position="absolute"
              top="0px"
              left="calc(50% - 244.5px - -0.5px)"
              buttontype="MachineButton"
              isDisabled={false}
              children="Download Latest HIM Version"
              {...getOverrideProps(overrides, "Development Environment")}
            ></Button>
            <Image
              width="40px"
              height="40px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="17px"
              left="24px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://i.imgur.com/OgQN41N.png"
              {...getOverrideProps(overrides, "PC1")}
            ></Image>
            <Image
              width="30px"
              height="40px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="17px"
              right="21px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://i.imgur.com/FbCLz5m.png"
              {...getOverrideProps(overrides, "Launch1")}
            ></Image>
          </View>
          <View
            width="489px"
            height="75px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Machine2")}
          >
            <Button
              height="75px"
              position="absolute"
              top="0px"
              left="calc(50% - 244.5px - 0px)"
              buttontype="MachineButton"
              isDisabled={false}
              children="Virtual Environment Sandbox"
              {...getOverrideProps(overrides, "Virtual Environment Sandbox")}
            ></Button>
            <Image
              width="40px"
              height="40px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="17px"
              left="24px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://i.imgur.com/OgQN41N.png"
              {...getOverrideProps(overrides, "PC2")}
            ></Image>
            <Image
              width="30px"
              height="40px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="17px"
              right="21px"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src="https://i.imgur.com/FbCLz5m.png"
              {...getOverrideProps(overrides, "Launch2")}
            ></Image>
          </View>
        </Flex>
      </Flex>
      <Image
        width="85px"
        height="86px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="39px"
        left="32px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/M6C6Wyj.png"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="30px"
        right="36px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
    </View>
  );
}
