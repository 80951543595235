/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function ADocs(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="1440px"
      height="1024px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "ADocs")}
      {...rest}
    >
      <View
        width="650px"
        height="327px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="388px"
        left="calc(50% - 325px - 0px)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Documents")}
      >
        <View
          width="660px"
          height="75px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="252px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Documents3")}
        >
          <Button
            height="75px"
            position="absolute"
            top="0px"
            left="0px"
            buttontype="FlatMachineButton"
            isDisabled={false}
            children="Development Client Reference Guide"
            {...getOverrideProps(overrides, "Document3")}
          ></Button>
          <Image
            width="40px"
            height="42px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17px"
            left="24px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "File3")}
          ></Image>
          <Image
            width="30px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17px"
            right="21px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "Download3")}
          ></Image>
        </View>
        <View
          width="660px"
          height="75px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="126px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Documents2")}
        >
          <Button
            height="75px"
            position="absolute"
            top="0px"
            left="0px"
            buttontype="FlatMachineButton"
            isDisabled={false}
            children="HIM Technical Guide"
            {...getOverrideProps(overrides, "Document2")}
          ></Button>
          <Image
            width="40px"
            height="42px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17px"
            left="24px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://i.imgur.com/ZHznCke.png"
            {...getOverrideProps(overrides, "File2")}
          ></Image>
          <Image
            width="30px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17px"
            right="21px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://i.imgur.com/2Vn7Y6n.png"
            {...getOverrideProps(overrides, "Download2")}
          ></Image>
        </View>
        <View
          width="660px"
          height="75px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Documents1")}
        >
          <Button
            height="75px"
            position="absolute"
            top="0px"
            left="0px"
            buttontype="FlatMachineButton"
            isDisabled={false}
            children="HIM Setup Guide"
            {...getOverrideProps(overrides, "Document1")}
          ></Button>
          <Image
            width="40px"
            height="42px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17px"
            left="24px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://i.imgur.com/ZHznCke.png"
            {...getOverrideProps(overrides, "File1")}
          ></Image>
          <Image
            width="30px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="17px"
            right="21px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="https://i.imgur.com/2Vn7Y6n.png"
            {...getOverrideProps(overrides, "Download1")}
          ></Image>
        </View>
      </View>
      <Flex
        gap="35px"
        direction="column"
        width="1120px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="167px"
        left="11.11%"
        right="11.11%"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Contents")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="KNECT Docs"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Choose which document to view"
          {...getOverrideProps(overrides, "Subtitle")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="33px"
        right="145px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
      <Image
        width="180px"
        height="61px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="29px"
        left="184px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/NN37r6R.png"
        {...getOverrideProps(overrides, "Word")}
      ></Image>
      <Image
        width="64px"
        height="65px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="27px"
        left="120px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/M6C6Wyj.png"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
