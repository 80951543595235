import { Amplify } from 'aws-amplify'

export function configureAmplify() {
    Amplify.configure(
    {
     Auth: 
      {
        Cognito:
        {
          userPoolId: 'us-east-1_4lhPVo0Qb',
          userPoolClientId: '6oahcsak4r99jf0867d07uccjv',
          identityPoolId:'us-east-1:29caa40d-7e30-4926-b8e9-7677650b952a'
        },
      },
      Storage:{
        S3:
        {
          bucket: 'kixfull',
          region: 'us-east-1',
          buckets:{
            'fullAceessBucket':{
              bucketName:'kixfull',
              region:'us-east-1'
            },
            'trialAceessBucket':{
              bucketName:'kixtrial',
              region:'us-east-1'
            }
          }
        },
      }
    }
   );
  }

  //Configure Storage with S3 bucket information
export function SetS3Config(bucket, level){
      Amplify.configure({ 
        bucket: bucket,
        level: level,
        region: 'us-east-1',  
        identityPoolId: 'us-east-1:29caa40d-7e30-4926-b8e9-7677650b952a'
    });
 }