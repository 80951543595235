import { AHIM } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import Code1 from '../../Assets/Images/Code 1.png'
import Comports from '../../Assets/Images/Comports.png'
import MonitorImage from '../../Assets/Images/MonitorIcon.png'
import LaunchImage from '../../Assets/Images/UploadIcon.png'
import { useEffect, useState } from 'react'
import { signOut } from 'aws-amplify/auth'
import { getUrl } from 'aws-amplify/storage'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../Redux/userSlice'

const HIMScreen = () => {
  const { isLoggedIn, profile } = useSelector(state => state.user);
  const [ showNoAccessDialog, setShowNoAccessDialog] = useState(false);
    const [deploymentToKioskDialog, setDeploymentToKisDialog] = useState(false);
    const [imageBtnDisable, setimageBtnDisable] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
        
    const chat = () => {
      navigate('/Chat')
    }

    const exit = async() => {
      try {
        await signOut();
        console.log('Sign out succeed');
      } catch (error) {
        console.error('error signing out: ', error);
      }
      dispatch(logout());
      navigate('/')
    }
    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }

    const toggleDeploymentToKisDialog = () => {
      setDeploymentToKisDialog(!deploymentToKioskDialog);
    };

    const changeDeploymentToKisImage = () => {
      setimageBtnDisable(!imageBtnDisable)
    }

    useEffect(() => {
      if(!deploymentToKioskDialog) {
        setimageBtnDisable(false)
      }
    }, [deploymentToKioskDialog])


    const handleDownload = () => {
      fetch(require('../../Assets/RDP/Kix - Demo Server.rdp'))
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = "Kix - Demo Server.rdp"; 
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error('Error fetching or creating Blob:', error);
        });
    };
    
    const handleDownloadHIM = async () => {
      try {
        if (profile === "Full") {  
          const result = await getUrl({
            path: 'public/KNect HIM.zip',
            options:{
              validateObjectExistence: true
            }
          });         
          const url = result.url;
          const link = document.createElement('a');
          link.href = url;
          link.download = 'KNect HIM.zip'; 
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
        else
          handleShowNoAccessDialog();
      } catch (error) {
        console.log(error);
      }
    }

    const handleShowNoAccessDialog = () => setShowNoAccessDialog(true);

    return (
      <div>
        {deploymentToKioskDialog && (
            <div className="dialog">
              <button className="previous-icon" onClick={changeDeploymentToKisImage} disabled={!imageBtnDisable}>&#x2190;</button>
              <button className="next-icon" onClick={changeDeploymentToKisImage} disabled={imageBtnDisable}>&#x2192;</button>
              <button className="close-icon" onClick={toggleDeploymentToKisDialog}>&#10005;</button>
              <div className="dialog-content">
                <img className="popup-image" src={Code1} alt="Code1" style={{display: imageBtnDisable? 'block' : 'none'}}/>
                <img className="popup-image" src={Comports} alt="Comports" style={{display: imageBtnDisable? 'none' : 'block'}}/>
              </div>
            </div>
        )}
        {showNoAccessDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>No access for downloading files!</h2>
              <button className='amplify-button' style={{margin: 20}} onClick={() => setShowNoAccessDialog(false)}>OK</button>            
            </div>
          </div>    
        )} 
        <AHIM className='AHIM'
        overrides={{
          "Logo": { src: logoImage},
          "Word": { src: wordImage},
          "Development Environment": { onClick: () => handleDownloadHIM() },
          "Virtual Environment Sandbox": { onClick: () => handleDownload(), isDisabled:true},
          "Help" : { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk')},
          "Exit" : { onClick: () => exit()},
          "PC1" : { src: MonitorImage},
          "PC2" : { src: MonitorImage},
          "Launch1" : { src: LaunchImage},
          "Launch2" : { src: LaunchImage},
          "FAQ":{ isDisabled : !isLoggedIn, onClick:() => chat()}
        }}/>
      </div>
    ) 
  }

export default HIMScreen;