import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const API = "https://m9a4v9uqck.execute-api.us-east-1.amazonaws.com/Test";

export async function PostCommentReplyApi(commentReply) {
  try {
      const token = (await fetchAuthSession()).tokens.idToken;
      const response = await axios.post(
        `${API}`,
        commentReply,
        {
          headers: {
            Authorization: token,
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      return { success: true, data: response.data };

    } catch (error) {
      console.error('Error posting reply:', error);
      return { success: false, message: 'An unexpected error occurred' };
    }
}

export async function PostCommentApi(comment) {
  try {
    const token = (await fetchAuthSession()).tokens.idToken;
    const response = await axios.post(
      `${API}`,
      comment,
      {
        headers: {
          Authorization: token,
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error:", error);
    return { success: false, message: 'An unexpected error occurred' };
  }
}

export async function GetAllCommenstApi() {
  try {
    const token = (await fetchAuthSession()).tokens.idToken;
    const response = await axios.get(`${API}`, {
      headers: {
        "Authorization": token,
        "Access-Control-Allow-Origin": '*',
      }
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false, message: 'An unexpected error occurred' };
  }
}

export async function GetSingleCommentApi(endPoint) {
  try {
    const token = (await fetchAuthSession()).tokens.idToken;
    const response = await axios.get(`${API}`+ endPoint, {
      headers: {
        "Authorization": token,
        "Access-Control-Allow-Origin": '*',
      }
    });

    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error fetching data:', error);
    return { success: false, message: 'An unexpected error occurred' };
  }
};