import { ALogin } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import { Loader } from '@aws-amplify/ui-react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout, login } from '../../Redux/userSlice'
import { 
  signOut, 
  signIn, 
  confirmSignUp, 
  resendSignUpCode, 
  resetPassword, 
  confirmResetPassword, 
  fetchUserAttributes 
} from 'aws-amplify/auth'

const LoginScreen = () => {
    const dispatch = useDispatch() 
    const navigate = useNavigate()
    const [password, setPassword] = useState('');
    const [username, setUserName] = useState(''); 
    const [error, setError] = useState('');
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [resetPasswordUsername, setResetPasswordUsername] = useState('');
    const [confirmCode, setConfirmCode] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [canLogin, setCanLogin] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isValidUsername, setIsValidUsername] = useState(true);
    const [isResetPasswordConfirmed, setResetPasswordConfirmed] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [showResetErrorDialog, setShowResetErrorDialog] = useState(false);
    const [showResetSuccessDialog, setShowResetSuccessDialog] = useState(false);
    const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
    const [showVerifyAccountDialog, setShowVerifyAccountDialog] = useState(false);

    const isLoggedIn = useSelector(state => state.user.isLoggedIn)

    const handleConfirmDialog = () => setShowVerifyAccountDialog(false);

    const handleErrorDialog = () => setShowErrorDialog(!showErrorDialog);

    const handleResetErrorDialog = () => setShowResetErrorDialog(false);

    const handleResetSuccessDialog = () => setShowResetSuccessDialog(!showResetSuccessDialog);
    
    const handlePasswordInput = (event) =>
    {
      setPassword(event.target.value);
      const passwordRegex = /^[a-zA-Z0-9-!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;
      const isValidPassword = passwordRegex.test(event.target.value);
      setIsValidPassword(isValidPassword);
    }

    const handleUserNameInput = (event) =>
    {
      setUserName(event.target.value);
      const usernameRegex = /^[a-zA-Z0-9]{4,20}$/;
      const isValidUsername = usernameRegex.test(event.target.value);
      setIsValidUsername(isValidUsername);
    }

    const handleConfirmationCodeInput = (event) => {
      setConfirmCode(event.target.value)
    };

    const handeResetPasswordDialog = () => {
      setShowResetPasswordDialog(!showResetPasswordDialog);
      setResetPasswordConfirmed(false);
      setResetPasswordUsername('');
    }

    const handleResetPassswordUsername = (event) => {
      setResetPasswordUsername(event.target.value);
    }

    const handleConfirmationCode = (event) => {
      setCode(event.target.value);
    } 

    const handleNewPassword = (event) => {
      setNewPassword(event.target.value);
      setPasswordsMatch(event.target.value === confirmNewPassword)
    } 

    const handleConfirmNewPassword = (event) => {
      setConfirmNewPassword(event.target.value);
      setPasswordsMatch(event.target.value === newPassword)
    }

    const handleLogin = async () => {
      try {
        await signIn({ username, password });
        const profile = (await fetchUserAttributes()).profile;
        dispatch(login({username,profile}));
        navigate('/Product')
      } catch (error) {
        console.log('error signing in', error);
        if (error.code === 'UserNotConfirmedException') 
        {
          setError(error.message);
          setShowVerifyAccountDialog(true);
          resendVerficationCode();
        } 
        else
        {
          setError(error.message);
          setShowErrorDialog(true);
        }
      }
    }

    const forgetPassword = async() => {
      try {
        const output = await resetPassword(resetPasswordUsername);
        handleResetPasswordNextSteps(output);
        setResetPasswordConfirmed(true);
      } catch (error) {
        console.log(error);
        let customErrorMessage = '';
        if (error.code === 'UserNotFoundException')
          customErrorMessage = `Cannot reset password for the user as there is no registered. Please sign up the account first.`;
        else if(error.code === 'InvalidParameterException')
          customErrorMessage = 'Cannot reset password for the user as there is registered but not verified usename. Please login the account to finish the verification.';
        else
          customErrorMessage = error.message;
        
        setError(customErrorMessage);
        setShowResetPasswordDialog(false);
        setShowResetErrorDialog(true);
      }
    }

    const forgotPasswordSubmit = async() => {
      try {
        setShowLoader(true);
        await confirmResetPassword({resetPasswordUsername, code, newPassword});
        handeResetPasswordDialog();
        handleResetSuccessDialog();
      } catch (error) {
        console.log(error);
        setError(error.message);
        setShowResetErrorDialog(true);
      } finally{
        setShowLoader(false);
      }
    }

    const handleResetPasswordNextSteps = (output) => {
      const { nextStep } = output;
      console.log(nextStep.resetPasswordStep);
    }

    const handleConfirmSignUp = async() => {
      try {
        await confirmSignUp({
          username: username,
          confirmCode: confirmCode
        });
        dispatch(login({username}));
        navigate('/Product');
      } catch (error) {
        console.log('error', error);
        setError(error.message);
        setShowErrorDialog(true);
      }
    }

    const resendVerficationCode = async() => {
      try {
        setShowLoader(true);
        const result = await resendSignUpCode(username);
        setEmail(result.CodeDeliveryDetails.Destination)
      }
      catch(error){
        console.log(error);
        setError(error.message);
        setShowErrorDialog(true);
      }finally{
        setShowLoader(false);
      }
    }

    const exit = async() => {
      try {
        await signOut();
        console.log('Sign out succeed');
      } catch (error) {
        console.error('error signing out: ', error);
      }
      dispatch(logout());
      navigate('/')
    }

    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    }

    const chat = () => {
      navigate('/Chat')
    }

    useEffect(() => {
      if(password 
        && username 
        && isValidUsername 
        && isValidPassword) 
        setCanLogin(true)
      else
        setCanLogin(false)
    }, [password, username, isValidUsername, isValidPassword])
  
    return(
      <div>
        {showResetPasswordDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <button className="close-icon" style={{color:'#000000'}} onClick={handeResetPasswordDialog}>&#10005;</button>
              <h2>Reset Password</h2>
              {!isResetPasswordConfirmed &&
                <div>
                  <input className='amplify-input' placeholder='Enter username' onChange={handleResetPassswordUsername} />
                  <button className='amplify-button' style={{margin: 20}} onClick={forgetPassword} disabled={resetPasswordUsername.length < 1}>Confirm</button>
                </div>
              }
              {isResetPasswordConfirmed && (  
                <div>
                  <input className='amplify-input' style={{margin:20}} placeholder='Enter Confirmation Code' onChange={handleConfirmationCode} />                    
                  <input className='amplify-input' style={{margin:20}} placeholder='Enter New Password' onChange={handleNewPassword} type='password'/>
                  <input className='amplify-input' style={{margin:20}} placeholder='Confirm Password' onChange={handleConfirmNewPassword} type='password' />
                  {
                    !passwordsMatch && newPassword.length > 0 && confirmNewPassword.length > 0 &&
                    <p style={{ color: 'red' }}>Passwords do not match</p>
                  }
                  <button className='amplify-button' style={{ margin: 10 }} onClick={forgotPasswordSubmit} disabled={code.length < 1 || !passwordsMatch}>Submit</button>
                  <button className='amplify-button' style={{ margin: 10 }} onClick={forgetPassword} disabled={showLoader}>
                  {
                    showLoader && <Loader style={{margin: 15}} size='small' />
                  }            
                    Resend code
                  </button>
                </div>
              )}            
            </div>
          </div>
        )}
        {showResetSuccessDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2 style={{color:'green'}}>You have reset password successfully!</h2>
              <button className='amplify-button' style={{margin: 20}} onClick={handleResetSuccessDialog}>OK</button>            
            </div>
          </div>    
        )}
        {showVerifyAccountDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <button className="close-icon" style={{color:'#000000'}} onClick={handleConfirmDialog}>&#10005;</button>
              <h2>Your email not verified</h2>
              <p className='popup-bodyText'>To log in, enter the code we emailed to {email},<br/> it may take a minute to arrive.</p>
              <input className='amplify-input' placeholder='Enter your code here' onChange={handleConfirmationCodeInput} />
              <div style={{margin: 20}}>
                <button className='amplify-button' style={{position:'relative'}} onClick={handleConfirmSignUp}>Confirm</button>
                <button className='amplify-button' style={{position:'relative', left:55 }} onClick={resendVerficationCode} disabled={showLoader}>
                  {showLoader && <Loader size='small' />}            
                  Resend Code
                </button>
              </div>
            </div>
          </div>
        )}
        {showErrorDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>Login Failed</h2>
              <p className='popup-bodyText'>{error}</p>
              <button className='amplify-button' style={{margin: 20}} onClick={handleErrorDialog}>OK</button>            
            </div>
          </div>
        )}
        {showResetErrorDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <h2>Reset Failed</h2>
              <p className='popup-bodyText' style={{width:450}}>{error}</p>
              <button className='amplify-button' style={{margin: 20}} onClick={handleResetErrorDialog}>OK</button>            
            </div>
          </div>
        )}
        <ALogin className='ALogin' 
        overrides={{
          "Logo": { src: logoImage},
          "Word": { src:wordImage},
          "Help" : { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk')},
          "Exit" : { onClick: () => exit()},
          "Password":{ onChange:(event) => handlePasswordInput(event), type:'password', hasError:!isValidPassword, errorMessage:'Password length must be at least 8 to 20'},
          "Username":{ onChange:(event) => handleUserNameInput(event), hasError:!isValidUsername, errorMessage:'Username length must be at least 4 to 20'},
          "FAQ":{ isDisabled : !isLoggedIn, onClick:() => chat()},
          "Login" : { isDisabled: !canLogin, onClick: () => handleLogin()},
          "ResetPassword":{ onClick: () => handeResetPasswordDialog()}
        }}/>
      </div>
    )
  }

export default LoginScreen;