/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function AProducts(props) {
  const { image, overrides, ...rest } = props;
  return (
    <View
      width="1440px"
      height="1024px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "AProducts")}
      {...rest}
    >
      <View
        width="1440px"
        height="328px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="399px"
        left="calc(50% - 720px - 0px)"
        borderRadius="35px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <View
          width="416px"
          height="312px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="7px"
          left="987px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Icon3")}
        >
          <Button
            position="absolute"
            top="10px"
            left="0px"
            buttontype="IconButton"
            isDisabled={false}
            children="The KNECT IoT platform supports&#xA;monitoring, analytics, and reporting"
            {...getOverrideProps(overrides, "Doc")}
          ></Button>
          <Text
            fontFamily="Urbanist"
            fontSize="36px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="43.20000076293945px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="252px"
            height="37px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="205px"
            left="calc(50% - 125px - 1px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="KNECT Docs"
            {...getOverrideProps(overrides, "KNECT Docs")}
          ></Text>
          <Image
            width="202px"
            height="200px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 101px - 0px)"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "DocIcon")}
          ></Image>
        </View>
        <View
          width="417px"
          height="311px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="8px"
          left="511px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Icon2")}
        >
          <Button
            position="absolute"
            top="9px"
            left="1px"
            buttontype="IconButton"
            isDisabled={false}
            children="The KNECT IoT platform supports&#xA;monitoring, analytics, and reporting"
            {...getOverrideProps(overrides, "Iot")}
          ></Button>
          <Image
            width="181px"
            height="200px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 90.5px - 0px)"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "IoTIcon")}
          ></Image>
          <Text
            fontFamily="Urbanist"
            fontSize="36px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="43.20000076293945px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="252px"
            height="37px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="205px"
            left="calc(50% - 125px - 0.5px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="KNECT IoT"
            {...getOverrideProps(overrides, "KNECT IoT")}
          ></Text>
        </View>
        <View
          width="417px"
          height="310px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="8px"
          left="36px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Icon1")}
        >
          <Button
            position="absolute"
            top="8px"
            left="1px"
            buttontype="IconButton"
            isDisabled={false}
            src="https://i.imgur.com/OQV9PAw.png"
            children="Our Hardware Integration Module&#xA;contains 40+ component APIs"
            {...getOverrideProps(overrides, "HIM")}
          ></Button>
          <Image
            width="185px"
            height="200px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="calc(50% - 92.5px - 0px)"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            {...getOverrideProps(overrides, "HIMIcon")}
          ></Image>
          <Text
            fontFamily="Urbanist"
            fontSize="36px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="43.20000076293945px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="252px"
            height="37px"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="205px"
            left="calc(50% - 125px - 0.5px)"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="KNECT HIM"
            {...getOverrideProps(overrides, "Knect HIM")}
          ></Text>
        </View>
      </View>
      <Flex
        gap="25px"
        direction="column"
        width="1116px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="74px"
        left="11.39%"
        right="11.11%"
        borderRadius="35px"
        padding="45px 43px 45px 43px"
        {...getOverrideProps(overrides, "Cover")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Kiosk Integration Experience"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Choose which KIOSK Integration Exchange product to explore"
          {...getOverrideProps(overrides, "Subtitle")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="33px"
        right="145px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
      <Image
        width="180px"
        height="61px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="29px"
        left="184px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/NN37r6R.png"
        {...getOverrideProps(overrides, "Word")}
      ></Image>
      <Image
        width="64px"
        height="65px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="27px"
        left="120px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/M6C6Wyj.png"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
