/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function BProducts(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="744px"
      height="1400px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "BProducts")}
      {...rest}
    >
      <Flex
        gap="40px"
        direction="column"
        width="680px"
        height="1275px"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="125px"
        left="32px"
        padding="10px 43px 10px 43px"
        {...getOverrideProps(overrides, "Content33868")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Kiosk Integration Experience"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Choose which KIOSK Integration Exchange product to explore"
          {...getOverrideProps(overrides, "Subtitle")}
        ></Text>
        <Flex
          gap="64px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          borderRadius="35px"
          padding="0px 128px 0px 128px"
          {...getOverrideProps(overrides, "Content882384")}
        >
          <View
            width="417px"
            height="310px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon1")}
          >
            <Button
              position="absolute"
              top="8px"
              left="1px"
              buttontype="IconButton"
              isDisabled={false}
              children="Our Hardware Integration Module&#xA;contains 40+ component APIs"
              {...getOverrideProps(overrides, "HIM")}
            ></Button>
            <Text
              fontFamily="Urbanist"
              fontSize="36px"
              fontWeight="700"
              color="rgba(255,255,255,1)"
              lineHeight="43.20000076293945px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="252px"
              height="37px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="205px"
              left="calc(50% - 125px - 1px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="KNECT HIM"
              {...getOverrideProps(overrides, "KNECT HIM")}
            ></Text>
            <Image
              width="185px"
              height="200px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 92.5px - 0.5px)"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "HIMIcon")}
            ></Image>
          </View>
          <View
            width="417px"
            height="311px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon2")}
          >
            <Button
              position="absolute"
              top="9px"
              left="1px"
              buttontype="IconButton"
              isDisabled={false}
              children="The KNECT IoT platform supports&#xA;monitoring, analytics, and reporting"
              {...getOverrideProps(overrides, "Iot")}
            ></Button>
            <Text
              fontFamily="Urbanist"
              fontSize="36px"
              fontWeight="700"
              color="rgba(255,255,255,1)"
              lineHeight="43.20000076293945px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="252px"
              height="37px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="205px"
              left="calc(50% - 125px - 1px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="KNECT IoT"
              {...getOverrideProps(overrides, "KNECT IoT")}
            ></Text>
            <Image
              width="181px"
              height="200px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 90.5px - 0.5px)"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "IoTIcon")}
            ></Image>
          </View>
          <View
            width="416px"
            height="312px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Icon3")}
          >
            <Button
              position="absolute"
              top="10px"
              left="0px"
              buttontype="IconButton"
              isDisabled={false}
              children="The KNECT IoT platform supports&#xA;monitoring, analytics, and reporting"
              {...getOverrideProps(overrides, "Doc")}
            ></Button>
            <Image
              width="202px"
              height="200px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0px"
              left="calc(50% - 101px - 0.5px)"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              {...getOverrideProps(overrides, "DocIcon")}
            ></Image>
            <Text
              fontFamily="Urbanist"
              fontSize="36px"
              fontWeight="700"
              color="rgba(255,255,255,1)"
              lineHeight="43.20000076293945px"
              textAlign="center"
              display="block"
              direction="column"
              justifyContent="unset"
              width="252px"
              height="37px"
              gap="unset"
              alignItems="unset"
              position="absolute"
              top="205px"
              left="calc(50% - 125px - 0.5px)"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="KNECT Docs"
              {...getOverrideProps(overrides, "KNECT Docs")}
            ></Text>
          </View>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="30px"
        right="36px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
      <Image
        width="85px"
        height="86px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="39px"
        left="32px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src="https://i.imgur.com/M6C6Wyj.png"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
